//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { connectRouter }      from 'connected-react-router';
import { combineReducers }    from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

import { AlertBoxReducer }              from '@slices/alertBox';
import { CalculationReducer }           from '@slices/calculation';
import { CustomerReducer }              from '@slices/customer';
import { DashboardReducer }             from '@slices/dashboard';
import { DepreciationEntryReducer }     from '@slices/depreciationEntry';
import { LayoutReducer }                from '@slices/layout';
import { LoadingReducer }               from '@slices/loading';
import { NewsReducer }                  from '@slices/news';
import { ObjectReducer }                from '@slices/object';
import { ObjectPoolReducer }            from '@slices/objectPool';
import { SmartUpdaterReducer }          from '@slices/smartUpdater';
import { StagingAuthenticationReducer } from '@slices/stagingAuthentication';
import { SubscriptionOptionReducer }    from '@slices/subscriptionOption';
import { UnitReducer }                  from '@slices/unit';
import { UserReducer }                  from '@slices/user';
import { WidgetReducer }                from '@slices/widget';
import { WindowReducer }                from '@slices/window';

export default (history) => withReduxStateSync(combineReducers({
    alertBox:              AlertBoxReducer,
    loading:               LoadingReducer,
    stagingAuthentication: StagingAuthenticationReducer,
    user:                  UserReducer,
    window:                WindowReducer,
    widget:                WidgetReducer,
    object:                ObjectReducer,
    objectPool:            ObjectPoolReducer,
    unit:                  UnitReducer,
    news:                  NewsReducer,
    customer:              CustomerReducer,
    smartUpdater:          SmartUpdaterReducer,
    depreciationEntry:     DepreciationEntryReducer,
    layout:                LayoutReducer,
    calculation:           CalculationReducer,
    dashboard:             DashboardReducer,
    subscriptionOption:    SubscriptionOptionReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
}));
