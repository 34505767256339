//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

export const selectEditCustomer = (state) => {
    return _.get(state, ['customer', 'edit']);
};

export const selectCustomers = (state) => {
    return _.get(state, ['customer', 'customers']);
};

export const selectCustomerPaging = (state) => {
    return _.get(state, ['customer', 'paging']);
};

export const selectCustomerPagingField = (field) => (state) => {
    return _.get(state, ['customer', 'paging', field], 1);
};

export const selectCustomerLoading = (state) => {
    return _.get(state, ['customer', 'loading']);
};
export const selectCustomerFilters = (state) => {
    return _.get(state, ['customer', 'filters']);
};

export default selectEditCustomer;
