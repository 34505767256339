//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }       from 'connected-react-router';
import _              from 'lodash';
import { takeLatest } from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';

import * as Api                    from '@api/index';
import ObjectFields                from '@constants/ObjectFields';
import PagingFields                from '@constants/PagingFields';
import Routes                      from '@constants/Routes';
import Hydra                       from '@helper/Hydra';
import Notification                from '@helper/Notification';
import SagaStateHelper             from '@helper/SagaStateHelper';
import { ObjectActions }           from '@slices/object';
import NumberMapper                from '@store/helper/NumberMapper';
import selectEditObject            from '@store/selectors/object';
import { selectObjectPagingField } from '@store/selectors/object';
import { selectObjectFilters }     from '@store/selectors/object';

function* saveObject() {
    const object       = yield SagaStateHelper.selector(selectEditObject);
    const response     = yield call(
        Api.context.object.saveObject,
        NumberMapper.mapFieldsToNumber(
            object,
            [
                ObjectFields.propertyManagerCosts,
                ObjectFields.unitManagerCosts,
                ObjectFields.reserves,
                ObjectFields.notaryFees,
                ObjectFields.mediationCosts,
                ObjectFields.realEstateTransferTax,
                ObjectFields.landShare,
                ObjectFields.yearOfConstruction,
            ],
        ),
    );
    const isNewPayload = {
        isNew: !_.get(object, 'iri'),
    };

    if (response.ok) {
        yield put(ObjectActions.saveObjectSucceeded(isNewPayload));
    } else {
        yield put(ObjectActions.saveObjectFailed(isNewPayload));
    }
}

function getTranslation(action, translationKey) {
    const prefix = (
        _.get(action, 'payload.isNew') ?
            'createObject' :
            'updateObject'
    );

    return `${prefix}.${translationKey}`;
}

function* saveObjectFailed(action) {
    Notification.error(getTranslation(action, 'error'));
}

function* saveObjectSucceeded(action) {
    Notification.success(getTranslation(action, 'success'));
    yield put(push(Routes.objects));
}

function* deleteObject(action) {
    const object   = _.get(action, 'payload.object');
    const response = yield call(
        Api.context.object.deleteObject,
        _.get(object, 'iri'),
    );

    if (response.ok) {
        yield put(ObjectActions.deleteObjectSucceeded());
    } else {
        yield put(ObjectActions.deleteObjectFailed());
    }
}

function* deleteObjectFailed() {
    Notification.error('deleteObject.error');
}

function* deleteObjectSucceeded() {
    Notification.success('deleteObject.success');
    yield put(ObjectActions.fetchObjects());
}

function* fetchObjects() {
    yield put(ObjectActions.setLoading({
        loading: true,
    }));
    const page         = yield SagaStateHelper.selector(selectObjectPagingField(PagingFields.currentPage));
    const objectFilter = yield SagaStateHelper.selector(selectObjectFilters);
    const response     = yield call(
        Api.context.object.fetchObjects,
        {
            page,
            ...objectFilter,
        },
    );

    if (response.ok) {
        const {
            members,
            pagingObject,
        } = Hydra.getMembersAndPaginationFromResponse(response.data);

        yield put(ObjectActions.fetchObjectsSucceeded({
            objects: members,
            paging:  pagingObject,
        }));

        const newCurrentPage = pagingObject[PagingFields.currentPage];

        if (page !== newCurrentPage) {
            yield put(ObjectActions.setCurrentPage({
                page: newCurrentPage,
            }));
        }
    } else {
        yield put(ObjectActions.fetchObjectsFailed());
    }

    yield put(ObjectActions.setLoading({
        loading: false,
    }));
}

function* fetchObjectsFailed() {
    Notification.error('fetchObjects.error');
}

function* editObject() {
    yield put(push(Routes.editObjects));
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([ObjectActions.saveObject().type],            saveObject),
        takeLatest([ObjectActions.saveObjectFailed().type],      saveObjectFailed),
        takeLatest([ObjectActions.saveObjectSucceeded().type],   saveObjectSucceeded),
        takeLatest([ObjectActions.deleteObject().type],          deleteObject),
        takeLatest([ObjectActions.deleteObjectFailed().type],    deleteObjectFailed),
        takeLatest([ObjectActions.deleteObjectSucceeded().type], deleteObjectSucceeded),
        takeLatest([
            ObjectActions.fetchObjects().type,
            ObjectActions.setCurrentPage().type,
            ObjectActions.addObjectFilter().type,
            ObjectActions.removeObjectFilter().type,
            ObjectActions.removeAllObjectFilters().type,
        ],                                                       fetchObjects),
        takeLatest([ObjectActions.fetchObjectsFailed().type],    fetchObjectsFailed),
        takeLatest([ObjectActions.editObject().type],            editObject),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
