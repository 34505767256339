//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import _                              from 'lodash';
import { useDispatch }                from 'react-redux';
import { useSelector }                from 'react-redux';
import { object as objectValidation } from 'yup';
import { array as arrayValidation }   from 'yup';
import { string }                     from 'yup';
import { boolean }                    from 'yup';

import DepreciationEntryList    from '@connected/DepreciationEntryList';
import DepreciationTemplateList from '@connected/DepreciationTemplateList';
import OutdoorFacilityList      from '@connected/OutdoorFacilityList';
import ParkingSpaceList         from '@connected/ParkingSpaceList';
import UnitFields               from '@constants/UnitFields';
import { getUnitStatusOptions } from '@constants/UnitStatus';
import InputRenderHelper        from '@helper/InputRenderHelper';
import Notification             from '@helper/Notification';
import ValidationHelper         from '@helper/ValidationHelper';
import useContextTranslator     from '@hooks/ContextTranslator';
import { useUnit }              from '@slices/unit';
import Button                   from '@stateless/atomic/Button';
import ButtonType               from '@stateless/atomic/Button/ButtonType';
import CheckBox                 from '@stateless/atomic/CheckBox';
import DropDown                 from '@stateless/atomic/DropDown';
import DropDownType             from '@stateless/atomic/DropDown/DropDownType';
import Headline                 from '@stateless/atomic/Headline';
import IconType                 from '@stateless/atomic/Icon/IconType';
import LabeledWrapper           from '@stateless/composed/LableledWrapper';
import LayoutScreen             from '@stateless/composed/LayoutScreen';
import PageTitle                from '@stateless/composed/PageTitle';
import selectEditUnit           from '@store/selectors/unit';

import styles from './styles.module.scss';

const EditUnitScreen = () => {
    const translator                    = useContextTranslator('screens.editUnitScreen');
    const dispatch                      = useDispatch();
    const unitActions                   = useUnit(dispatch);
    const unit                          = useSelector(selectEditUnit);
    const isNewUnit                     = !_.get(unit, 'iri');
    const buttonText                    = (
        isNewUnit ?
            'createUnit' :
            'saveUnit'
    );
    const titleText                     = (
        isNewUnit ?
            'createNewUnit' :
            'editUnit'
    );
    const pageTitle                     = (
        isNewUnit ?
            'pageCreateTitle' :
            'pageEditTitle'
    );
    const [validations, setValidations] = useState({});
    const carParkingSpaceSchema         = objectValidation({
        [UnitFields.carParkingSpaceType]:          string(),
        [UnitFields.carParkingSpacePurchasePrice]: string().required().min(1),
        [UnitFields.carParkingSpaceMonthlyRent]:   string().required().min(1),
    });
    const outdoorFacilitySchema         = objectValidation({
        [UnitFields.outdoorFacilityDescription]:          string(),
        [UnitFields.outdoorFacilityDepreciationPercent]:  string().required(),
        [UnitFields.outdoorFacilityDepreciationDuration]: string().required().min(1),
        [UnitFields.outdoorFacilityPurchasePrice]:        string().required().min(1),
    });
    const depreciationEntrySchema       = objectValidation({
        [UnitFields.depreciationEntryDescription]:      string(),
        [UnitFields.depreciationEntryType]:             string().required(),
        [UnitFields.depreciationEntryValueType]:        string().required(),
        [UnitFields.depreciationEntryYearFrom]:         string().required().min(1),
        [UnitFields.depreciationEntryYearTo]:           string().required().min(1).when(UnitFields.depreciationEntryYearFrom, {
            // eslint-disable-next-line rulesdir/no-shortcut-variable-name
            is:   (yearFrom) => yearFrom && yearFrom.length > 0,
            then: (schema) => schema.test({
                test(yearTo) {
                    const yearFrom = this.parent[UnitFields.depreciationEntryYearFrom];

                    return (
                        !yearTo ||
                        !yearFrom ||
                        parseInt(yearTo, 10) >= parseInt(yearFrom, 10)
                    );
                },
            }),
        }),
        [UnitFields.depreciationEntryDepreciationRate]: string().required(),
    });
    const unitFormSchema                = objectValidation({
        [UnitFields.object]:               string(),
        [UnitFields.number]:               string().nullable(),
        [UnitFields.locationOnProperty]:   string().nullable(),
        [UnitFields.spaceInSquareMeters]:  string().required(),
        [UnitFields.roomCount]:            string().required(),
        [UnitFields.status]:               string(),
        [UnitFields.showUnitInCalculator]: boolean(),
        [UnitFields.purchasePriceField]:   string(),
        [UnitFields.calculationData]:      objectValidation().shape({
            [UnitFields.purchasePriceField]: string().required().min(1),
            [UnitFields.monthlyRentField]:   string().required().min(1),
            [UnitFields.inventoryValue]:     string(),
            [UnitFields.renovationCosts]:    string(),
        }),
        [UnitFields.carParkingSpaces]:     arrayValidation().of(carParkingSpaceSchema),
        [UnitFields.outdoorFacilities]:    arrayValidation().of(outdoorFacilitySchema),
        [UnitFields.depreciationEntries]:  arrayValidation().of(depreciationEntrySchema).test({
            name:    'no-overlapping-years-with-same-value-type',
            message: 'noOverlappingYearsWithSameValueType',
            test:    (depreciationEntries) => {
                const years = {};

                return _.every(depreciationEntries, (entry) => {
                    const yearFrom  = _.get(entry, UnitFields.depreciationEntryYearFrom);
                    const yearTo    = _.get(entry, UnitFields.depreciationEntryYearTo);
                    const valueType = _.get(entry, UnitFields.depreciationEntryValueType);

                    if (
                        !yearFrom ||
                        !yearTo ||
                        !valueType
                    ) {
                        return true;
                    }

                    for (let yearIndex = parseInt(yearFrom, 10); yearIndex <= parseInt(yearTo, 10); yearIndex++) {
                        const key = `${yearIndex}-${valueType}`;

                        if (_.has(years, key)) {
                            return false;
                        }

                        _.set(years, key, true);
                    }

                    return true;
                });
            },
        }),
    });
    const customValidationErrorKeys     = [
        'depreciationEntries',
    ];

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            if (_.has(validations, path)) {
                setValidations(_.omit(validations, path));
            }

            _.set(changeSet, path, value);
            unitActions.editUnitData({
                edit: changeSet,
            });
        };
    }

    const inputRenderer = new InputRenderHelper({
        validations,
        data: unit,
        onUpdateData,
    });

    function onUpdateClicked() {
        const currentValidations = ValidationHelper.getValidations(
            unitFormSchema,
            unit,
            setValidations,
        );

        if (ValidationHelper.hasNoValidations(currentValidations)) {
            unitActions.saveUnit();
        } else {
            const prefix       = (
                isNewUnit ?
                    'createUnit' :
                    'updateUnit'
            );
            const customErrors = _.pick(currentValidations, customValidationErrorKeys);

            if (_.size(customErrors) > 0) {
                _.every(Object.values(customErrors), (errorMessage) => Notification.error(errorMessage));

                if (_.size(currentValidations) === 1) {
                    return;
                }
            }
            Notification.error(`${prefix}.validationError`);
        }
    }

    function onAdditionalIncomeTillEndClicked(event) {
        const currentState = _.get(unit, UnitFields.additionalIncomeTillEnd, false);

        onUpdateData(UnitFields.additionalIncomeDuration)(null);
        onUpdateData(UnitFields.additionalIncomeTillEnd)(!currentState);
    }

    return (
        <>
            <PageTitle
                title={translator.t(pageTitle)}
            />
            <LayoutScreen>
                <div className={styles.editUnitScreen}>
                    <Headline title={translator.t(titleText)} />
                    <div className={styles.contentColumns}>
                        <div>
                            <LabeledWrapper
                                label={translator.t('unitLabel')}
                                columns={2}
                            >
                                {inputRenderer.renderTextInput({
                                    label: translator.t('apartmentNumber'),
                                    field: UnitFields.number,
                                })}
                                {inputRenderer.renderTextInput({
                                    label: translator.t('unitLocationInObject'),
                                    field: UnitFields.locationOnProperty,
                                })}
                                {inputRenderer.renderNumberInput({
                                    label:    translator.t('spaceInM2'),
                                    field:    UnitFields.spaceInSquareMeters,
                                    required: true,
                                })}
                                {inputRenderer.renderNumberInput({
                                    label:    translator.t('numberOfRooms'),
                                    field:    UnitFields.roomCount,
                                    required: true,
                                })}
                                <DropDown
                                    dropDownType={DropDownType.whiteWithLabel}
                                    label={translator.t('status')}
                                    options={getUnitStatusOptions()}
                                    value={_.get(unit, UnitFields.status)}
                                    onChange={onUpdateData(UnitFields.status)}
                                />
                                <DropDown
                                    label={translator.t('showUnitInCalculator')}
                                    dropDownType={DropDownType.whiteWithLabel}
                                    options={[
                                        {
                                            value: true,
                                            label: translator.t('showUnitInCalculatorYes'),
                                        },
                                        {
                                            value: false,
                                            label: translator.t('showUnitInCalculatorNo'),
                                        },
                                    ]}
                                    value={_.get(unit, UnitFields.showUnitInCalculator)}
                                    onChange={onUpdateData(UnitFields.showUnitInCalculator)}
                                />
                            </LabeledWrapper>
                            <LabeledWrapper
                                label={translator.t('calculationData')}
                                columns={2}
                            >
                                {inputRenderer.renderCurrencyInput({
                                    label:    translator.t('buyingPrice'),
                                    field:    UnitFields.purchasePrice,
                                    required: true,
                                })}
                                {inputRenderer.renderCurrencyInput({
                                    label:    translator.t('monthlyRent'),
                                    field:    UnitFields.monthlyRent,
                                    required: true,
                                })}
                                {inputRenderer.renderCurrencyInput({
                                    label:    translator.t('renovationCosts'),
                                    field:    UnitFields.renovationCosts,
                                    required: false,
                                })}
                                {inputRenderer.renderCurrencyInput({
                                    label:    translator.t('inventoryValue'),
                                    field:    UnitFields.inventoryValue,
                                    required: false,
                                })}
                            </LabeledWrapper>
                            <LabeledWrapper
                                label={translator.t('additionalIncomeTitle')}
                                alignVerticalCenter={false}
                                columns={2}
                            >
                                {inputRenderer.renderCurrencyInput({
                                    label:    translator.t('additionalIncome'),
                                    field:    UnitFields.additionalIncome,
                                    required: false,
                                })}
                                <div className={styles.customDepreciationWrapper}>
                                    {inputRenderer.renderNumberInput({
                                        label:         translator.t('additionalIncomeDuration'),
                                        field:         UnitFields.additionalIncomeDuration,
                                        required:      true,
                                        disabled:      _.get(unit, UnitFields.additionalIncomeTillEnd),
                                        formatToFixed: true,
                                        decimalsLimit: 0,
                                    })}
                                    <CheckBox
                                        checked={_.get(unit, UnitFields.additionalIncomeTillEnd)}
                                        onClick={onAdditionalIncomeTillEndClicked}
                                        text={translator.t('additionalIncomeTillEnd')}
                                        noMarginBottom={true}
                                        size={25}
                                    />
                                </div>
                            </LabeledWrapper>
                        </div>
                        <div>
                            <LabeledWrapper
                                label={translator.t('kfzPlaces')}
                                columns={1}
                            >
                                <ParkingSpaceList
                                    validations={validations}
                                    onUpdateData={onUpdateData}
                                />
                            </LabeledWrapper>
                            <LabeledWrapper
                                label={translator.t('outdoorFacilities')}
                                columns={1}
                            >
                                <OutdoorFacilityList
                                    validations={validations}
                                    onUpdateData={onUpdateData}
                                />
                            </LabeledWrapper>
                        </div>
                        <div className={styles.fullWidth}>
                            <LabeledWrapper
                                label={translator.t('depreciationEntries')}
                                columns={1}
                            >
                                <DepreciationTemplateList />
                                <DepreciationEntryList
                                    validations={validations}
                                    onUpdateData={onUpdateData}
                                />
                            </LabeledWrapper>
                        </div>
                    </div>
                    <Button
                        type={ButtonType.edgy}
                        iconLeft={IconType.circlePlus}
                        text={translator.t(buttonText)}
                        onClick={onUpdateClicked}
                    />
                </div>
            </LayoutScreen>
        </>
    );
};

export default EditUnitScreen;
