//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update from 'immutability-helper';
import _      from 'lodash';

class SliceFilter {
    static addFilter(state, action) {
        const filterKey   = _.get(action, 'payload.filterKey', null);
        const filterValue = _.get(action, 'payload.filterValue', null);

        if (_.isNull(filterKey)) {
            return state;
        }

        const filters = _.get(state, 'filters', null);

        if (_.isNull(filters)) {
            return update(state, {
                $set: {
                    ...state,
                    filters: {
                        [filterKey]: filterValue,
                    },
                },
            });
        }

        return update(state, {
            filters: {
                [filterKey]: {
                    $set: filterValue,
                },
            },
        });
    }

    static removeFilter(state, action) {
        const filterKey = _.get(action, 'payload.filterKey', null);

        if (_.isNull(filterKey)) {
            return state;
        }

        const filters = _.get(state, 'filters', null);

        if (_.isNull(filters)) {
            return state;
        }

        return update(state, {
            filters: {
                $unset: [
                    filterKey,
                ],
            },
        });
    }

    static removeAllFilter(state, action) {
        return update(state, {
            filters: {
                $set: {},
            },
        });
    }
}

export default SliceFilter;
