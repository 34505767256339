//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';

import { getUrl }           from '@api';
import PropTypes            from '@components/PropTypes';
import CalculationFields    from '@constants/CalculationFields';
import CustomerFields       from '@constants/CustomerFields';
import Address              from '@helper/Address';
import Formatter            from '@helper/Formatter';
import Notification         from '@helper/Notification';
import useContextTranslator from '@hooks/ContextTranslator';
import { useCalculation }   from '@slices/calculation';
import { useCustomer }      from '@slices/customer';
import Button               from '@stateless/atomic/Button';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import Expandable           from '@stateless/atomic/Expandable';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import IconType             from '@stateless/atomic/Icon/IconType';
import RowMenu              from '@stateless/atomic/RowMenu';
import Spacer               from '@stateless/atomic/Spacer';
import ConfirmModal         from '@stateless/composed/ConfirmModal';
import GroupedTable         from '@stateless/composed/GroupedTable';

const propTypes = {
    customer: PropTypes.object,
};

const CustomerTable = ({
    customer = null,
}) => {
    const translator                            = useContextTranslator('screens.customersScreen');
    const dispatch                              = useDispatch();
    const customerActions                       = useCustomer(dispatch);
    const calculationActions                    = useCalculation(dispatch);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    function isDeleted(calculation) {
        return _.get(calculation, 'unit.deleted', false);
    }

    function getDownloadPath(calculation) {
        return _.get(calculation, 'downloadPath', null);
    }

    function hasDownloadPath(calculation) {
        return getDownloadPath(calculation) !== null;
    }

    function onEditCustomerClicked() {
        customerActions.editCustomer({
            customer,
        });
    }

    function onDeleteCustomerClicked() {
        setShowDeleteModal(true);
    }

    function onDeleteCustomerModalClosed() {
        setShowDeleteModal(false);
    }

    function onDeleteCustomerConfirmed() {
        setShowDeleteModal(false);
        customerActions.deleteCustomer({
            customer,
        });
    }

    function onEditCalculationClicked(calculation) {
        calculationActions.openCalculation();
        calculationActions.fetchCalculation({
            iri: _.get(calculation, 'iri'),
        });
    }

    function onDeleteCalculationClicked(calculation) {
        calculationActions.deleteCalculation({
            iri: _.get(calculation, 'iri'),
        });
    }

    function onDownloadCalculationClicked(calculation) {
        window.open(
            getUrl(getDownloadPath(calculation)),
            '_blank',
        );
        Notification.success('downloadCalculationPDF.success');
    }

    const expandableMenuItems = [
        {
            id:      1,
            text:    I18n.t('edit'),
            icon:    IconType.pen,
            onClick: onEditCustomerClicked,
        },
        {
            id:   2,
            text: translator.t('newCalculation'),
            icon: IconType.plus,
        },
        {
            id:      3,
            text:    I18n.t('delete'),
            icon:    IconType.trash,
            onClick: onDeleteCustomerClicked,
        },
    ];
    const rowMenuItems        = [
        {
            id:      1,
            text:    I18n.t('edit'),
            icon:    IconType.pen,
            onClick: onEditCalculationClicked,
        },
        {
            id:      2,
            text:    I18n.t('download'),
            icon:    IconType.download,
            onClick: onDownloadCalculationClicked,
            visible: hasDownloadPath,
        },
        {
            id:      3,
            text:    I18n.t('delete'),
            icon:    IconType.trash,
            onClick: onDeleteCalculationClicked,
        },
    ];
    const deletedRowMenuItems = [
        {
            id:      1,
            text:    I18n.t('download'),
            icon:    IconType.download,
            onClick: onDownloadCalculationClicked,
            visible: hasDownloadPath,
        },
        {
            id:      2,
            text:    I18n.t('delete'),
            icon:    IconType.trash,
            onClick: onDeleteCalculationClicked,
        },
    ];

    function renderRowMenu(calculation) {
        const rowItemsToUse = (
            isDeleted(calculation) ?
                deletedRowMenuItems :
                rowMenuItems
        );

        if (_.isEmpty(rowItemsToUse)) {
            return null;
        }

        const visibleItems = _.filter(
            rowItemsToUse,
            (rowItem) => {
                const visible = _.get(rowItem, 'visible', true);

                if (_.isFunction(visible)) {
                    return visible(calculation);
                }

                return true;
            },
        );

        return (
            <RowMenu
                menuItems={visibleItems}
                targetData={calculation}
            />
        );
    }

    const getFromContactPerson   = (field) => {
        const contactPersonOneField = _.get(customer, [CustomerFields.contactPersonOne, field]);
        const contactPersonTwoField = _.get(customer, [CustomerFields.contactPersonTwo, field]);

        return contactPersonOneField || contactPersonTwoField;
    };
    const getAddressAsString     = () => {
        const address = getFromContactPerson(CustomerFields.contactPersonAddress);

        return Address.buildAddressString(address);
    };
    const getNameByContactPerson = () => {
        const firstname = _.get(customer, [CustomerFields.contactPersonOne, CustomerFields.contactPersonFirstname]);
        const lastname  = _.get(customer, [CustomerFields.contactPersonOne, CustomerFields.contactPersonLastname]);

        return translator.t('fullName', {
            firstname,
            lastname,
        });
    };
    const rowColumns             = [
        {
            columns: 3,
            items:   [
                {
                    title:    getNameByContactPerson(),
                    subtitle: getAddressAsString(),
                },
            ],
        },
        {
            columns: 7,
            items:   [
                {
                    prefixLine: true,
                    title:      I18n.t('email'),
                    subtitle:   getFromContactPerson(CustomerFields.contactPersonEmail),
                },
                {
                    prefixLine: true,
                    title:      I18n.t('phone'),
                    subtitle:   getFromContactPerson(CustomerFields.contactPersonPhonePrivate),
                },
                {
                    prefixLine: true,
                    title:      I18n.t('mobile'),
                    subtitle:   getFromContactPerson(CustomerFields.contactPersonMobile),
                },
            ],
        },
    ];
    const tableGroups            = [
        {
            id:         'customer_groups_1',
            columnSpan: 9,
            children:   I18n.t('unit'),
        },
        {
            id:         'customer_groups_2',
            columnSpan: 3,
            children:   I18n.t('buyingPrice'),
        },
        {
            id:         'customer_groups_3',
            columnSpan: 6,
            children:   I18n.t('rent'),
        },
        {
            id:         'customer_groups_4',
            columnSpan: 13,
            children:   I18n.t('calculation'),
        },
    ];
    const baseTableCell          = {
        disabled: isDeleted,
    };
    const tableCells             = [
        {
            ...baseTableCell,
            id:           'customer_cells_1',
            columnSpan:   3,
            children:     I18n.t('date'),
            propertyName: CustomerFields.updatedAt,
            formatter:    Formatter.formatToDateTime,
        },
        {
            ...baseTableCell,
            id:           'customer_cells_2',
            columnSpan:   4,
            children:     I18n.t('object'),
            propertyName: CustomerFields.propertyName,
        },
        {
            ...baseTableCell,
            id:           'customer_cells_3',
            columnSpan:   2,
            children:     I18n.t('unitNumber'),
            propertyName: CustomerFields.number,
        },
        {
            ...baseTableCell,
            id:           'customer_cells_4',
            columnSpan:   2,
            children:     I18n.t('unit'),
            propertyName: CustomerFields.singlePurchasePrice,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            ...baseTableCell,
            id:           'customer_cells_5',
            columnSpan:   1,
            children:     I18n.t('kfz'),
            propertyName: CustomerFields.carParkingPurchasePrice,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            ...baseTableCell,
            id:           'customer_cells_6',
            columnSpan:   2,
            children:     I18n.t('cold'),
            propertyName: CustomerFields.singleMonthlyRent,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            ...baseTableCell,
            id:           'customer_cells_7',
            columnSpan:   2,
            children:     I18n.t('rent'),
            propertyName: CustomerFields.carParkingMonthlyRent,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            ...baseTableCell,
            id:           'customer_cells_8',
            columnSpan:   2,
            children:     I18n.t('yield'),
            propertyName: CustomerFields.returnYield,
            formatter:    Formatter.formatToPercent,
        },
        {
            ...baseTableCell,
            id:           'customer_cells_9',
            columnSpan:   4,
            children:     I18n.t('financePart'),
            propertyName: CustomerFields.financingAmount,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            ...baseTableCell,
            id:           'customer_cells_10',
            columnSpan:   2,
            children:     I18n.t('ownCapital'),
            propertyName: CustomerFields.equityCapital,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            ...baseTableCell,
            id:           'customer_cells_11',
            columnSpan:   3,
            children:     I18n.t('ownCapitalYield'),
            infoText:     I18n.t('ownCapitalYieldInfoText'),
            propertyName: CustomerFields.returnOnCapital,
            formatter:    Formatter.formatToPercent,
        },
        {
            ...baseTableCell,
            id:           'customer_cells_12',
            columnSpan:   3,
            children:     I18n.t('monthlyOutcome'),
            propertyName: CustomerFields.cashflowPerMonth,
            formatter:    Formatter.formatToCurrency(),
        },
        {
            ...baseTableCell,
            id:           'customer_cells_13',
            columnSpan:   1,
            children:     null,
            customRender: renderRowMenu,
        },
    ];

    function onNewCalculationClicked() {
        calculationActions.openCalculation();
        calculationActions.updateCalculationData({
            calculation: {
                [CalculationFields.customer]: customer,
            },
        });
    }

    return (
        <React.Fragment key={customer.iri}>
            <Expandable
                expandButtonText={translator.t('showCalculations')}
                expandButtonColumns={3}
                rowColumns={rowColumns}
                menuItems={expandableMenuItems}
            >
                <Headline
                    title={I18n.t('calculations')}
                    type={HeadlineType.headline4}
                />
                <Spacer height={20} />
                <GroupedTable
                    tableCells={tableCells}
                    tableGroups={tableGroups}
                    dataItems={customer.calculations}
                />
                <Spacer height={20} />
                <Button
                    type={ButtonType.edgy}
                    text={translator.t('newCalculation')}
                    iconLeft={IconType.circlePlus}
                    onClick={onNewCalculationClicked}
                />
                <ConfirmModal
                    show={showDeleteModal}
                    onConfirm={onDeleteCustomerConfirmed}
                    title={translator.t('deleteCustomerTitle')}
                    text={translator.t('deleteCustomerText')}
                    onClose={onDeleteCustomerModalClosed}
                />
            </Expandable>
        </React.Fragment>
    );
};

CustomerTable.propTypes = propTypes;

export default CustomerTable;
