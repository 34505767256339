//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import styles      from '@screens/EditUnitScreen/styles.module.scss';
import { useUnit } from '@slices/unit';

const propTypes = {};

const DepreciationTemplateList = () => {
    const dispatch               = useDispatch();
    const unitActions            = useUnit(dispatch);
    const templateGroups         = useSelector((state) => state.depreciationEntry.templateGroups);
    const onTemplateGroupClicked = (templateGroup) => () => {
        _.forEach(templateGroup.depreciationEntryTemplates, (entry) => {
            unitActions.addDepreciationTemplateAsEntry({
                template: entry,
            });
        });
    };

    function renderTemplateGroupItems() {
        return _.map(templateGroups, (templateGroup) => {
            return (
                <button
                    onClick={onTemplateGroupClicked(templateGroup)}
                    className={styles.depreciationTemplateItem}
                >
                    {templateGroup.description}
                </button>
            );
        });
    }

    return (
        <div className={styles.depreciationTemplateWrapper}>
            <div className={styles.depreciationTemplateItems}>
                {renderTemplateGroupItems()}
            </div>
        </div>
    );
};

DepreciationTemplateList.propTypes = propTypes;

export default DepreciationTemplateList;
