//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes            from '@components/PropTypes';
import SearchInputType      from '@connected/SearchInput/SearchInputType';
import CustomerFilter       from '@constants/CustomerFilter';
import ObjectFilter         from '@constants/ObjectFilter';
import useContextTranslator from '@hooks/ContextTranslator';
import { useCustomer }      from '@slices/customer';
import { useObject }        from '@slices/object';
import LabeledInput         from '@stateless/atomic/LabeledInput';

import styles from './styles.module.scss';

const propTypes = {
    type: PropTypes.oneOfObjectValues(SearchInputType),
};

const SearchInput = ({
    type = null,
}) => {
    const { t }           = useContextTranslator('components.searchInput');
    const dispatch        = useDispatch();
    const customerActions = useCustomer(dispatch);
    const objectActions   = useObject(dispatch);

    function handleCustomerSearch(searchValue) {
        customerActions.addCustomerFilter({
            filterKey:   CustomerFilter.Search,
            filterValue: searchValue,
        });
    }

    function handleObjectSearch(searchValue) {
        objectActions.addObjectFilter({
            filterKey:   ObjectFilter.Search,
            filterValue: searchValue,
        });
    }

    function handleSearchValueChange(searchValue) {
        switch (type) {
            case SearchInputType.customer:
                handleCustomerSearch(searchValue);

                break;
            case SearchInputType.object:
                handleObjectSearch(searchValue);

                break;
        }
    }

    return (
        <div className={styles.searchInput}>
            <LabeledInput
                onChange={handleSearchValueChange}
                label={t('search')}
            />
        </div>
    );
};

SearchInput.propTypes = propTypes;

export default SearchInput;
