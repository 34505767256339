//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const objectFields = Object.freeze({
    calculationData:     'calculationData',
    carParkingSpaces:    'carParkingSpaces',
    depreciationEntries: 'depreciationEntries',
});

export default Object.freeze({
    object:                                  'property',
    number:                                  'number',
    roomCount:                               'roomCount',
    spaceInSquareMeters:                     'spaceInSquareMeters',
    status:                                  'status',
    showUnitInCalculator:                    'showUnitInCalculator',
    unitCarParkingPurchasePrice:             'carParkingPurchasePrice',
    singlePurchasePrice:                     'singlePurchasePrice',
    locationOnProperty:                      'locationOnProperty',
    purchasePricePerSquareMeter:             'purchasePricePerSquareMeter',
    calculationData:                         objectFields.calculationData,
    carParkingSpaces:                        objectFields.carParkingSpaces,
    depreciationEntries:                     objectFields.depreciationEntries,
    depreciationEntryType:                   'type',
    depreciationEntryValueType:              'valueType',
    depreciationEntryDescription:            'description',
    depreciationEntryDepreciationRate:       'depreciationRate',
    depreciationEntryYearFrom:               'yearFrom',
    depreciationEntryYearTo:                 'yearTo',
    outdoorFacilities:                       'outdoorFacilities',
    purchasePriceField:                      'purchasePrice',
    monthlyRentField:                        'monthlyRent',
    purchasePrice:                           [objectFields.calculationData, 'purchasePrice'],
    monthlyRent:                             [objectFields.calculationData, 'monthlyRent'],
    additionalIncome:                        [objectFields.calculationData, 'additionalIncome'],
    additionalIncomeDuration:                [objectFields.calculationData, 'additionalIncomeDuration'],
    additionalIncomeTillEnd:                 [objectFields.calculationData, 'additionalIncomeTillEnd'],
    inventoryValue:                          [objectFields.calculationData, 'inventoryValue'],
    renovationCosts:                         [objectFields.calculationData, 'renovationCosts'],
    calculationDataPurchasePrice:            'purchasePrice',
    calculationDataMonthlyRent:              'monthlyRent',
    calculationDataAdditionalIncome:         'additionalIncome',
    calculationDataAdditionalIncomeDuration: 'additionalIncomeDuration',
    calculationDataAdditionalIncomeTillEnd:  'additionalIncomeTillEnd',
    calculationDataInventoryValue:           'inventoryValue',
    calculationDataRenovationCosts:          'renovationCosts',
    carParkingSpacePurchasePrice:            'purchasePrice',
    carParkingSpaceMonthlyRent:              'monthlyRent',
    carParkingSpaceType:                     'parkingType',
    outdoorFacilityDescription:              'description',
    outdoorFacilityPurchasePrice:            'purchasePrice',
    outdoorFacilityDepreciationPercent:      'depreciationPercent',
    outdoorFacilityDepreciationDuration:     'depreciationDuration',
    singleMonthlyRent:                       'singleMonthlyRent',
    unitCarParkingMonthlyRent:               'carParkingMonthlyRent',
    monthlyRentPerSquareMeter:               'monthlyRentPerSquareMeter',
    returnYield:                             'returnYield',
    objectType:                              'propertyType',
});
