//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import Skeleton        from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import CustomerTable             from '@connected/CustomerTable';
import SearchInput               from '@connected/SearchInput';
import SearchInputType           from '@connected/SearchInput/SearchInputType';
import PagingFields              from '@constants/PagingFields';
import useContextTranslator      from '@hooks/ContextTranslator';
import { useCustomer }           from '@slices/customer';
import Button                    from '@stateless/atomic/Button';
import ButtonType                from '@stateless/atomic/Button/ButtonType';
import Headline                  from '@stateless/atomic/Headline';
import HeadlineType              from '@stateless/atomic/Headline/HeadlineType';
import IconType                  from '@stateless/atomic/Icon/IconType';
import PaginationBar             from '@stateless/atomic/PaginationBar';
import LayoutScreen              from '@stateless/composed/LayoutScreen';
import PageTitle                 from '@stateless/composed/PageTitle';
import { selectCustomers }       from '@store/selectors/customer';
import { selectCustomerPaging }  from '@store/selectors/customer';
import { selectCustomerLoading } from '@store/selectors/customer';

import styles from './styles.module.scss';

const CustomersScreen = () => {
    const translator      = useContextTranslator('screens.customersScreen');
    const dispatch        = useDispatch();
    const customerActions = useCustomer(dispatch);
    const customers       = useSelector(selectCustomers);
    const customerPaging  = useSelector(selectCustomerPaging);
    const customerLoading = useSelector(selectCustomerLoading);

    useEffect(() => {
        customerActions.setCurrentPage();
        customerActions.removeAllCustomerFilters();
    }, []);

    function onNewCustomerClicked() {
        customerActions.editCustomer();
    }

    function renderExpandableCustomerItems() {
        return _.map(customers, (customer) => {
            return (
                <CustomerTable
                    customer={customer}
                />
            );
        });
    }

    function renderCustomers() {
        if (customerLoading) {
            return _.times(5, () => {
                return <Skeleton height={50} />;
            });
        }

        return renderExpandableCustomerItems();
    }

    const handlePageClick = (event) => {
        const page = event.selected + 1;

        customerActions.setCurrentPage({
            page,
        });
    };

    return (
        <>
            <PageTitle
                title={I18n.t('customers')}
            />
            <LayoutScreen>
                <div className={styles.customersScreen}>
                    <Headline
                        type={HeadlineType.headline1}
                        title={translator.t('headline')}
                    />
                    <p>
                        {translator.t('text')}
                    </p>
                    <Button
                        type={ButtonType.edgy}
                        text={translator.t('new')}
                        iconLeft={IconType.circlePlus}
                        onClick={onNewCustomerClicked}
                    />
                    <SearchInput type={SearchInputType.customer} />
                    <div className={styles.customers}>
                        {renderCustomers()}
                    </div>
                    <PaginationBar
                        initialPage={customerPaging[PagingFields.currentPage] - 1}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={customerPaging[PagingFields.lastPage]}
                    />
                </div>
            </LayoutScreen>
        </>
    );
};

export default CustomersScreen;
