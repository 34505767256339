//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import Skeleton        from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import BreadCrumbBar                        from '@connected/BreadCrumbBar';
import CustomerDetailTable                  from '@connected/CustomerDetailTable';
import SearchInput                          from '@connected/SearchInput';
import SearchInputType                      from '@connected/SearchInput/SearchInputType';
import CalculationFields                    from '@constants/CalculationFields';
import PagingFields                         from '@constants/PagingFields';
import useContextTranslator                 from '@hooks/ContextTranslator';
import { useCalculation }                   from '@slices/calculation';
import { useCustomer }                      from '@slices/customer';
import Headline                             from '@stateless/atomic/Headline';
import HeadlineType                         from '@stateless/atomic/Headline/HeadlineType';
import PaginationBar                        from '@stateless/atomic/PaginationBar';
import Spacer                               from '@stateless/atomic/Spacer';
import CalculationNavigationBar             from '@stateless/composed/CalculationNavigationBar';
import LayoutScreen                         from '@stateless/composed/LayoutScreen';
import PageTitle                            from '@stateless/composed/PageTitle';
import { selectCurrentCalculationCustomer } from '@store/selectors/calculation';
import { selectCustomers }                  from '@store/selectors/customer';
import { selectCustomerPaging }             from '@store/selectors/customer';
import { selectCustomerLoading }            from '@store/selectors/customer';

import styles from './styles.module.scss';

const CalculationCustomerScreen = () => {
    const translator         = useContextTranslator('screens.calculationScreen');
    const customer           = useSelector(selectCurrentCalculationCustomer);
    const customers          = useSelector(selectCustomers);
    const dispatch           = useDispatch();
    const customerActions    = useCustomer(dispatch);
    const calculationActions = useCalculation(dispatch);
    const customerPaging     = useSelector(selectCustomerPaging);
    const customerLoading    = useSelector(selectCustomerLoading);

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            _.set(changeSet, path, value);
            calculationActions.updateCalculationData({
                calculation: changeSet,
            });
        };
    }

    const onCustomerChanged         = (newCustomer) => () => {
        onUpdateData(CalculationFields.customer)(newCustomer);
    };
    const renderCustomerDetailTable = () => {
        return _.map(customers, (renderCustomer) => {
            return (
                <CustomerDetailTable
                    key={renderCustomer.id}
                    isCustomerSelected={renderCustomer?.iri === customer?.iri}
                    onCustomerSelected={onCustomerChanged(renderCustomer)}
                    customer={renderCustomer}
                />
            );
        });
    };

    function renderCustomers() {
        if (customerLoading) {
            return _.times(5, () => {
                return <Skeleton height={50} />;
            });
        }

        return renderCustomerDetailTable();
    }

    const handlePageClick = (event) => {
        const page = event.selected + 1;

        customerActions.setCurrentPage({
            page,
        });
    };

    useEffect(() => {
        customerActions.setCurrentPage();
        customerActions.removeAllCustomerFilters();
    }, []);

    return (
        <>
            <PageTitle
                title={I18n.t('calculation')}
            />
            <LayoutScreen>
                <BreadCrumbBar />
                <div className={styles.calculationCustomerScreen}>
                    <div className={styles.headerNavigationRow}>
                        <Headline
                            type={HeadlineType.headline2}
                            title={translator.t('calculationCustomerTitle')}
                        />
                        <CalculationNavigationBar />
                    </div>
                    <Spacer height={10} />
                    <SearchInput type={SearchInputType.customer} />
                    <div className={styles.customerList}>
                        {renderCustomers()}
                    </div>
                    <PaginationBar
                        initialPage={customerPaging[PagingFields.currentPage] - 1}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={customerPaging[PagingFields.lastPage]}
                    />
                    <CalculationNavigationBar />
                </div>
            </LayoutScreen>
        </>
    );
};

export default CalculationCustomerScreen;
