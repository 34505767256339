//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes             from '@components/PropTypes';
import DepreciationType      from '@constants/DepreciationType';
import DepreciationValueType from '@constants/DepreciationValueType';
import UnitFields            from '@constants/UnitFields';
import InputRenderHelper     from '@helper/InputRenderHelper';
import useContextTranslator  from '@hooks/ContextTranslator';
import { useUnit }           from '@slices/unit';
import DropDown              from '@stateless/atomic/DropDown';
import DropDownType          from '@stateless/atomic/DropDown/DropDownType';
import LabeledInputType      from '@stateless/atomic/LabeledInput/LabeledInputType';
import FacilityList          from '@stateless/composed/FacilityList';
import selectEditUnit        from '@store/selectors/unit';

const propTypes = {
    onUpdateData: PropTypes.func,
    validations:  PropTypes.object,
};

const DepreciationEntryList = ({
    validations,
    onUpdateData,
}) => {
    const dispatch                       = useDispatch();
    const unitActions                    = useUnit(dispatch);
    const unit                           = useSelector(selectEditUnit);
    const translator                     = useContextTranslator('components.depreciationEntryList');
    const depreciationTypeSelection      = Object.keys(DepreciationType).map((key) => {
        return {
            label: translator.t(key),
            value: _.get(DepreciationType, key),
        };
    });
    const depreciationValueTypeSelection = Object.keys(DepreciationValueType).map((key) => {
        return {
            label: translator.t(key),
            value: _.get(DepreciationValueType, key),
        };
    });
    const depreciationEntries            = _.get(unit, UnitFields.depreciationEntries, []);
    const inputRenderer                  = new InputRenderHelper({
        validations,
        data: unit,
        onUpdateData,
    });
    const gridColumnStyle                = {
        gridTemplateColumns: '4fr 2fr 1fr 3fr 1fr 1fr 1fr',
    };
    const depreciationEntriesFields      = [
        {
            headerText:  translator.t('description'),
            renderInput: (index) => inputRenderer.renderTextInput({
                type:  LabeledInputType.lightWithoutLabel,
                field: [UnitFields.depreciationEntries, index, UnitFields.depreciationEntryDescription],
            }),
        },
        {
            headerText:  translator.t('type'),
            renderInput: (index) => {
                const typeField = [UnitFields.depreciationEntries, index, UnitFields.depreciationEntryType];

                return (
                    <DropDown
                        dropDownType={DropDownType.white}
                        options={depreciationTypeSelection}
                        value={_.get(unit, typeField)}
                        onChange={onUpdateData(typeField)}
                    />
                );
            },
        },
        {
            headerText:  translator.t('depreciationRate'),
            renderInput: (index) => inputRenderer.renderPercentInput({
                type:  LabeledInputType.lightWithoutLabel,
                field: [UnitFields.depreciationEntries, index, UnitFields.depreciationEntryDepreciationRate],
            }),
        },
        {
            headerText:  translator.t('valueType'),
            renderInput: (index) => {
                const typeField = [UnitFields.depreciationEntries, index, UnitFields.depreciationEntryValueType];

                return (
                    <DropDown
                        dropDownType={DropDownType.white}
                        options={depreciationValueTypeSelection}
                        value={_.get(unit, typeField)}
                        onChange={onUpdateData(typeField)}
                    />
                );
            },
        },
        {
            headerText:  translator.t('fromYear'),
            renderInput: (index) => inputRenderer.renderNumberInput({
                type:  LabeledInputType.lightWithoutLabel,
                field: [UnitFields.depreciationEntries, index, UnitFields.depreciationEntryYearFrom],
            }),
        },
        {
            headerText:  translator.t('toYear'),
            renderInput: (index) => inputRenderer.renderNumberInput({
                type:  LabeledInputType.lightWithoutLabel,
                field: [UnitFields.depreciationEntries, index, UnitFields.depreciationEntryYearTo],
            }),
        },
    ];

    function addDepreciationEntryClicked() {
        unitActions.addDepreciationEntry();
    }

    function deleteDepreciationEntryClicked(index) {
        unitActions.deleteDepreciationEntry({
            index,
        });
    }

    return (
        <FacilityList
            emptyText={translator.t('noDepreciationEntries')}
            addText={translator.t('addDepreciationEntry')}
            addFacilityClicked={addDepreciationEntryClicked}
            deleteFacilityClicked={deleteDepreciationEntryClicked}
            gridColumnStyle={gridColumnStyle}
            facilityList={depreciationEntries}
            facilityFields={depreciationEntriesFields}
        />
    );
};

DepreciationEntryList.propTypes = propTypes;

export default DepreciationEntryList;
