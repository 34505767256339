//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    templateGroups: [],
});

const depreciationEntrySlice = createSlice({
    name:     'depreciationEntry',
    initialState,
    reducers: {
        fetchDepreciationEntryTemplateGroups:          LoadingLevelHelper.increaseLoadingEmptyReducer(I18n.t('loading.depreciationEntry.templates')),
        fetchDepreciationEntryTemplateGroupsFailed:    LoadingLevelHelper.decreaseLoadingEmptyReducer(),
        fetchDepreciationEntryTemplateGroupsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                templateGroups: {
                    $set: _.get(action, 'payload.templateGroups', []),
                },
            });
        }),
        reset:                                         () => {
            return {
                ...initialState,
            };
        },
    },
});

export const DepreciationEntryActions = depreciationEntrySlice.actions;

export const DepreciationEntryReducer = depreciationEntrySlice.reducer;

export const useDepreciationEntries = (dispatch) => bindActionCreators(DepreciationEntryActions, dispatch);

export default depreciationEntrySlice;
