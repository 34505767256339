//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LOCATION_CHANGE } from 'connected-react-router';
import { REHYDRATE }       from 'redux-persist';
import { all }             from 'redux-saga/effects';
import { takeLatest }      from 'redux-saga/effects';

import { AlertBoxActions }              from '@slices/alertBox';
import { BrowserActions }               from '@slices/browser';
import { LoadingActions }               from '@slices/loading';
import { NavigationActions }            from '@slices/navigation';
import { PreloadActions }               from '@slices/preload';
import { StagingAuthenticationActions } from '@slices/stagingAuthentication';
import { WidgetActions }                from '@slices/widget';
import { WindowActions }                from '@slices/window';

// Sagas
import AlertBoxSagas                   from './alertBox';
import BrowserSagas                    from './browser';
import CalculationSagas                from './calculation';
import CustomerSagas                   from './customer';
import DashboardSagas                  from './dashboard';
import DepreciationTemplateGroupsSagas from './depreciationTemplateGroups';
import LoadingSagas                    from './loading';
import NavigationSagas                 from './navigation';
import NewsSagas                       from './news';
import ObjectSagas                     from './object';
import ObjectPoolSagas                 from './objectPool';
import PreLoadSagas                    from './preload';
import StagingAuthenticationSagas      from './stagingAuthentication';
import SubscriptionOptionSagas         from './subscriptionOption';
import UnitSagas                       from './unit';
import UserSagas                       from './user';
import WidgetSagas                     from './widget';
import WindowSagas                     from './window';

const callAlertBoxSagas = () => {
    return [
        // @formatter:off
        takeLatest([AlertBoxActions.showErrorAlertTranslated().type],   AlertBoxSagas.showErrorAlertTranslated),
        takeLatest([AlertBoxActions.showSuccessAlertTranslated().type], AlertBoxSagas.showSuccessAlertTranslated),
        // @formatter:on
    ];
};

const callBrowserSagas = () => {
    return [
        // @formatter:off
        takeLatest([BrowserActions.scrollToTop().type], BrowserSagas.scrollToTop),
        // @formatter:on
    ];
};

const callStagingAuthenticationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.resetState().type],              StagingAuthenticationSagas.reset),
        takeLatest([StagingAuthenticationActions.authenticate().type], StagingAuthenticationSagas.authenticate),
        // @formatter:on
    ];
};

const callLoadingSagas = () => {
    return [
        // @formatter:off
        takeLatest([LoadingActions.overlayClicked().type], LoadingSagas.overlayClicked),
        // @formatter:on
    ];
};

const callNavigationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.redirect().type], NavigationSagas.redirect),
        // @formatter:on
    ];
};

const callLocationChangeSagas = () => {
    return [
        // @formatter:off
        takeLatest([LOCATION_CHANGE], NavigationSagas.locationChange),
        takeLatest([LOCATION_CHANGE], LoadingSagas.resetClickCount),
        // @formatter:on
    ];
};

const callWindowSagas = () => {
    return [
        // @formatter:off
        takeLatest([WindowActions.windowBecameVisible().type], WindowSagas.windowBecameVisible),
        takeLatest([WindowActions.windowGotFocus().type],      WindowSagas.windowGotFocus),
        // @formatter:on
    ];
};

const callWidgetSagas = () => {
    return [
        // @formatter:off
        takeLatest([WidgetActions.fetchUnits().type],               WidgetSagas.fetchUnits),
        takeLatest([WidgetActions.fetchCalculationResult().type],   WidgetSagas.fetchCalculationResult),
        takeLatest([
            WidgetActions.setUserId().type,
            WidgetActions.setExternalUserId().type,
        ],                                                          WidgetSagas.setUserId),
        takeLatest([WidgetActions.setCalculationFields().type],     WidgetSagas.triggerFetchCalculationResult),
        takeLatest([WidgetActions.fetchColorSettings().type],       WidgetSagas.fetchColorSettings),
        takeLatest([WidgetActions.sendContactForm().type],          WidgetSagas.sendContactForm),
        takeLatest([WidgetActions.sendContactFormFailed().type],    WidgetSagas.sendContactFormFailed),
        takeLatest([WidgetActions.sendContactFormSucceeded().type], WidgetSagas.sendContactFormSucceeded),
        // @formatter:on
    ];
};

const callRehydrateSagas = () => {
    return [
        // @formatter:off
        takeLatest([REHYDRATE],                         LoadingSagas.rehydrate),
        takeLatest([REHYDRATE],                         PreLoadSagas.rehydrate),
        takeLatest([PreloadActions.preLoadData().type], PreLoadSagas.preLoadData),
        // @formatter:on
    ];
};

function* root() {
    yield all([
        ...callAlertBoxSagas(),
        ...callBrowserSagas(),
        ...callStagingAuthenticationSagas(),
        ...callLoadingSagas(),
        ...callNavigationSagas(),
        ...callLocationChangeSagas(),
        ...callWindowSagas(),
        ...callRehydrateSagas(),
        ...callWidgetSagas(),
        ...UserSagas.callSagas(),
        ...ObjectSagas.callSagas(),
        ...UnitSagas.callSagas(),
        ...CustomerSagas.callSagas(),
        ...CalculationSagas.callSagas(),
        ...DashboardSagas.callSagas(),
        ...SubscriptionOptionSagas.callSagas(),
        ...NewsSagas.callSagas(),
        ...ObjectPoolSagas.callSagas(),
        ...DepreciationTemplateGroupsSagas.callSagas(),
    ]);
}

export default {
    root,
};
