//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    street:                'address.street',
    houseNumber:           'address.houseNumber',
    postalCode:            'address.postalCode',
    city:                  'address.city',
    landShare:             'keyTaxData.landShare',
    description:           'description',
    propertyManagerCosts:  'ownerCosts.propertyManagerCosts',
    unitManagerCosts:      'ownerCosts.unitManagerCosts',
    reserves:              'ownerCosts.reserves',
    realEstateTransferTax: 'additionalPurchasingCosts.realEstateTransferTax',
    notaryFees:            'additionalPurchasingCosts.notaryFees',
    mediationCosts:        'additionalPurchasingCosts.mediationCosts',
    yearOfConstruction:    'yearOfConstruction',
});
