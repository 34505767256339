//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import PropTypes            from '@components/PropTypes';
import CustomerFields       from '@constants/CustomerFields';
import Address              from '@helper/Address';
import NumberFormat         from '@helper/NumberFormat';
import useContextTranslator from '@hooks/ContextTranslator';
import Button               from '@stateless/atomic/Button';
import ButtonColor          from '@stateless/atomic/Button/ButtonColor';
import ButtonSize           from '@stateless/atomic/Button/ButtonSize';
import Expandable           from '@stateless/atomic/Expandable';
import IconType             from '@stateless/atomic/Icon/IconType';
import LabeledWrapper       from '@stateless/composed/LableledWrapper';
import LabeledWrapperColor  from '@stateless/composed/LableledWrapper/LabeledWrapperColor';

import styles from './styles.module.scss';

const propTypes = {
    customer:           PropTypes.object,
    isCustomerSelected: PropTypes.bool,
    onCustomerSelected: PropTypes.func,
};

const CustomerDetailTable = ({
    customer           = null,
    isCustomerSelected = false,
    onCustomerSelected = _.noop,
}) => {
    const translator                 = useContextTranslator('screens.calculationScreen');
    const getPersonAddressString     = (person = CustomerFields.contactPersonOne) => {
        const address = _.get(customer, [person, CustomerFields.contactPersonAddress]);

        return Address.buildAddressString(address);
    };
    const getPersonName              = (person = CustomerFields.contactPersonOne) => {
        const firstname = _.get(customer, [person, CustomerFields.contactPersonFirstname]);
        const lastname  = _.get(customer, [person, CustomerFields.contactPersonLastname]);

        return translator.t('fullName', {
            firstname,
            lastname,
        });
    };
    const getPersonEmail             = (person = CustomerFields.contactPersonOne) => {
        return _.get(customer, [person, CustomerFields.contactPersonEmail]);
    };
    const getPersonPhone             = (person = CustomerFields.contactPersonOne) => {
        return _.get(customer, [person, CustomerFields.contactPersonPhonePrivate]);
    };
    const getPersonMobile            = (person = CustomerFields.contactPersonOne) => {
        return _.get(customer, [person, CustomerFields.contactPersonMobile]);
    };
    const getFieldValue              = (field) => {
        return _.get(customer, field);
    };
    const rowColumns                 = [
        {
            columns: 3,
            items:   [
                {
                    title:    getPersonName(),
                    subtitle: getPersonAddressString(),
                },
            ],
        },

    ];
    const renderLabeledText          = (labelKey, text) => {
        return (
            <div className={styles.labeledText}>
                <label>
                    {translator.t(labelKey)}
                </label>
                <div>
                    {_.trim(text) || '-'}
                </div>
            </div>
        );
    };
    const onSelectCustomerClicked    = (event) => {
        event.stopPropagation();

        onCustomerSelected(customer);
    };
    const renderSelectCustomerButton = () => {
        const buttonColor = isCustomerSelected
            ? ButtonColor.green
            : ButtonColor.white;
        const iconType    = isCustomerSelected
            ? IconType.circleCheck
            : IconType.circlePlus;
        const text        = isCustomerSelected
            ? translator.t('customerSelected')
            : translator.t('selectCustomer');

        return (
            <div className={styles.buttonWrapper}>
                <Button
                    iconLeft={iconType}
                    text={text}
                    onClick={onSelectCustomerClicked}
                    color={buttonColor}
                    size={ButtonSize.content}
                />
            </div>
        );
    };

    return (
        <React.Fragment key={customer.iri}>
            <Expandable
                rowColumns={rowColumns}
                customElement={renderSelectCustomerButton()}
            >
                <div className={styles.divider}>
                    <LabeledWrapper
                        label={translator.t('firstPerson')}
                        columns={1}
                        color={LabeledWrapperColor.gray}
                    >
                        {renderLabeledText('name', getPersonName())}
                        {renderLabeledText('address', getPersonAddressString())}
                        {renderLabeledText('email', getPersonEmail())}
                        {renderLabeledText('phone', getPersonPhone())}
                        {renderLabeledText('mobile', getPersonMobile())}
                    </LabeledWrapper>
                    <LabeledWrapper
                        label={translator.t('secondPerson')}
                        columns={1}
                        color={LabeledWrapperColor.gray}
                    >
                        {renderLabeledText('name', getPersonName(CustomerFields.contactPersonTwo))}
                        {renderLabeledText('address', getPersonAddressString(CustomerFields.contactPersonTwo))}
                        {renderLabeledText('email', getPersonEmail(CustomerFields.contactPersonTwo))}
                        {renderLabeledText('phone', getPersonPhone(CustomerFields.contactPersonTwo))}
                        {renderLabeledText('mobile', getPersonMobile(CustomerFields.contactPersonTwo))}
                    </LabeledWrapper>
                    <LabeledWrapper
                        label={translator.t('taxesAndFinance')}
                        columns={1}
                        color={LabeledWrapperColor.gray}
                    >
                        {renderLabeledText('maritalStatus', I18n.t(`familyStatusOptions.${_.lowerCase(getFieldValue(CustomerFields.civilStatus))}`))}
                        {renderLabeledText('churchTaxPercentage', `${getFieldValue(CustomerFields.churchTaxPercentage)} %`)}
                        {renderLabeledText('yearlyTaxableIncome', NumberFormat.formatToEuroString(getFieldValue(CustomerFields.annualTaxableIncome)))}
                        {renderLabeledText('incomeRaise', `${getFieldValue(CustomerFields.increaseInIncome)} %`)}
                        {renderLabeledText('incomeRaiseInterval', I18n.t('format.interval', {
                            value: getFieldValue(CustomerFields.increaseInIncomeInterval),
                        }))}
                    </LabeledWrapper>
                </div>
            </Expandable>
        </React.Fragment>
    );
};

CustomerDetailTable.propTypes = propTypes;

export default CustomerDetailTable;
