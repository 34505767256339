//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiUrls from '@constants/ApiUrls';

const depreciationTemplateGroupsApi = (api) => {
    return {
        fetchDepreciationTemplateGroups: () => {
            return api.get(ApiUrls.API_DEPRECIATION_TEMPLATE_GROUP_URL);
        },
    };
};

export default (api) => depreciationTemplateGroupsApi(api);
