//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { takeLatest } from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';

import * as Api                     from '@api/index';
import Hydra                        from '@helper/Hydra';
import Notification                 from '@helper/Notification';
import { DepreciationEntryActions } from '@slices/depreciationEntry';

function* fetchDepreciationEntryTemplateGroups() {
    const response = yield call(Api.context.depreciationTemplateGroup.fetchDepreciationTemplateGroups);

    if (response.ok) {
        const templateGroups = Hydra.getMembersFromResponse(response.data);

        yield put(DepreciationEntryActions.fetchDepreciationEntryTemplateGroupsSucceeded({
            templateGroups,
        }));
    } else {
        yield put(DepreciationEntryActions.fetchDepreciationEntryTemplateGroupsFailed());
    }
}

function* fetchDepreciationEntryTemplateGroupsFailed() {
    Notification.error('fetchDepreciationTemplateGroups.error');
}

const callSagas = () => {
    return [
        // @formatter:off
        takeLatest([DepreciationEntryActions.fetchDepreciationEntryTemplateGroups().type],       fetchDepreciationEntryTemplateGroups),
        takeLatest([DepreciationEntryActions.fetchDepreciationEntryTemplateGroupsFailed().type], fetchDepreciationEntryTemplateGroupsFailed),
        // @formatter:on
    ];
};

export default {
    callSagas,
};
